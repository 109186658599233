import React from "react";

import { IoCheckmarkSharp } from "react-icons/io5";

const PackagesFeatures = ({ packageFeatures }) => {
	return (
		<div>
			{packageFeatures?.map(
				(plan, index) =>
					plan?.selected && (
						<h2
							style={{
								color: "#ADB5B9",
								fontSize: "18px",
								fontWeight: "400",
								display: "flex",
								justifyContent: "start",
								alignItems: "start",
								marginBottom: "0px",
							}}
							key={index}>
							<IoCheckmarkSharp
								style={{
									color: "#3AE374",
									display: "inline-block",
									marginLeft: "0.1em",
									width: "22px",
									height: "22px",
								}}
							/>

							<span
								style={{
									color: [
										"مجانا رحلة تجارية الى الامارات",
										"مجانا رحلة تجارية الى الصين",
										"مجانا رحلة تجارية الى الأمارات",
									].includes(plan?.name)
										? "#1dbbbe"
										: "#011723",
									fontWeight: "400",
									display: "inline-block",
									width: "100%",
									lineHeight: "1.6",
									whiteSpace: [
										"مجانا رحلة تجارية الى الامارات",
										"مجانا رحلة تجارية الى الصين",
										"مجانا رحلة تجارية الى الأمارات",
									].includes(plan?.name)
										? undefined
										: "normal",
								}}>
								<>{plan?.name}</>
							</span>
						</h2>
					)
			)}
		</div>
	);
};

export default PackagesFeatures;
